import { configSymbol } from "@formkit/vue";
import gsap from "gsap";

export default {
  computed: {
    selectedBuilding() {
      return this.$store.getters.selectedBuilding;
    },

    abbUser() {
      return this.$store.getters.abb;
    },

    isMobile() {
      return this.window.width < 768;
    },

    //we can add more to these, this is better than performing logic in components.Using these classes in the building profile section
    colorClasses() {
      return {
        class_1: this.abbUser
          ? "bg-gray-700 text-black"
          : "bg-gray-200 text-gray-966",
        class_2: this.abbUser ? "bg-white" : "bg-gray-300",
      };
    },
  },

  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },

  unmounted() {
    window.removeEventListener("resize", this.handleResize);
  },

  data() {
    return {
      window: {
        width: 0,
      },
    };
  },

  methods: {
    hasRequiredRole(role) {
      const roles = this.$store.getters["user"].roles;
      if (!roles.includes(role)) {
        this.$router.replace({ name: "home" }, () => {});
      }
    },

    setBuildingName(ctx, type) {
      const { id, building_name, building_label } = ctx;

      return building_label || building_name || `${type} ${id}`;
    },
    setBuildingType(list, type) {
      if (list.length === 0 || type === 0) {
        return null;
      }
      const building_type = list.find((item) => item.value === type);

      return building_type
        ? building_type.tag
          ? building_type.tag
          : null
        : null;
    },

    getImgUrl(img) {
      return require("@/assets/icons/" + img);
    },

    renderImg(img) {
      return `<img src="@/assests/icons/${img}"/>`;
    },

    handleResize() {
      this.window.width = window.innerWidth;
    },

    onToggleToolTipHover(target, index, prop, value, activeMobile) {
      const el = typeof index === "number" ? `${target}-${index}` : target;

      if (window.innerWidth < 1024 && !activeMobile) {
        return;
      }

      gsap.to(el, {
        [prop]: value,
        duration: 0.5,
        ease: "circ.out",
      });
    },

    async getBuildingVersion() {
      let url =
        `${process.env.VUE_APP_REGIONAL_SERVICES_PROXY_URL}/buildings/` +
        `${Number(this.$route.params.slug)}/data-service/latest/buildings/` +
        `metadata?tag_ids=528&building_ids=${Number(this.$route.params.slug)}`;
      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      try {
        const response = await this.fetchJigo(url, requestOptions);

        const data = await response.json();

        let version = 'V1'
        if (Array.isArray(data) && data.length > 0 ) {
          version = data[0].cur_val
        }
        return version
      } catch (error) {
        console.log(error);
      }
    },

    async fetchJigo(url, requestOptions) {
      const token = this.$store.getters["token"];

      if (requestOptions.headers === undefined) {
        requestOptions.headers = {};
      }
      requestOptions.headers.authorization = `Bearer ${token}`;
      requestOptions.headers = {
        ...requestOptions.headers,
        "Strict-Transport-Security": "max-age=31536000; includeSubDomains",
        "X-Frame-Options": "deny",
        "X-Content-Type-Options": "nosniff",
        "Referrer-Policy": "same-origin",
        "Content-Security-Policy":
          "default-src 'self'; script-src 'self' 'unsafe-inline';",
      };

      const response = await fetch(encodeURI(url), requestOptions);
      return response;
    },

    async fetchApi(type, category, categoryType, unit, opts) {
      let url = "";

      /*
			type: scope
			category: fuel, electricity
			categoryType: Motor Gasoline
			unit: "l"
			*/
      if (type === "scope1") {
        url = `${process.env.VUE_APP_GLOBAL_SERVICES_PROXY_URL}/decarb-service/latest/ratio/${category}/${categoryType}?unit=${unit}&year=${opts.year}`;
      } else {
        url = `${process.env.VUE_APP_GLOBAL_SERVICES_PROXY_URL}/decarb-service/latest/ratio/${category}/${categoryType}?unit=${unit}&year=${opts.year}&country=${opts.country}`;
        if (opts.province) {
          url = `${process.env.VUE_APP_GLOBAL_SERVICES_PROXY_URL}/decarb-service/latest/ratio/${category}/${categoryType}?unit=${unit}&year=${opts.year}&country=${opts.country}&province=${opts.province}`;
        }
      }
      try {
        const requestOptions = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        };
        const response = await this.fetchJigo(url, requestOptions);
        const data = await response.json();

        return data;
      } catch (error) {
        console.log(error);
      }
    },

    async fetchUrjanetApi(buildingName, intervalStart, intervalEnd, opts) {
      let url = `${process.env.VUE_APP_GLOBAL_SERVICES_PROXY_URL}/urjanet-service/latest/bills/${buildingName}/bills?interval_start=${intervalStart}&interval_end=${intervalEnd}&rate_component=${opts.rateComponent}`;

      try {
        const requestOptions = {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        };
        const response = await this.fetchJigo(url, requestOptions);
        const data = await response.json();
        return data;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
