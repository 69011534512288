<template>
  <div id="app" class="w-screen font-atlasgrotesk">
    <Icons />
    <TopLine v-if="app_loaded" :style="{ maxWidth: maxWindowsWidth }" />

    <div
      id="main-content"
      class="main-content flex flex-col min-h-[100vh]"
      :class="ariaClassTag"
      :style="{ marginLeft }"
    >
      <TermOfUsePopup
        v-if="app_loaded"
        v-show="showPopup"
        class="z-[999]"
        :dateTitles="dateTitles"
        @close="createUserEutu"
      />
      <SideBar
        v-if="app_loaded && !isIframe"
        class="font-atlasgrotesk"
        :mobileMenuOpen="mobileMenuOpen"
        @toggleMobileMenu="toggleMobileMenu"
      />
      <Header
        v-if="app_loaded && !isIframe"
        @toggleMobileMenu="toggleMobileMenu"
      />
      <router-view v-if="app_loaded" />
    </div>
    <transition name="fade-loader" mode="out-in">
      <Loader v-if="!app_loaded" />
    </transition>
  </div>
</template>

<script>
import Loader from "@/components/Loader";
import Icons from "@/components/Icons";
import Header from "@/components/Header";
import TermOfUsePopup from "./components/TermOfUsePopup";
import SideBar from "./views/layouts/sidebar";
import { getBrowserName, getUserDevice } from "./utils";
import { sidebarWidth, maxWindowsWidth } from "./views/layouts/sidebar/state";
import { MUTATION_CREATE_NEXUS_USER_EUTU } from "./graphql";
import TopLine from "./components/topLine";
import { encryptId } from "./utils/hash-id";

export default {
  name: "App",

  setup() {
    return { sidebarWidth, maxWindowsWidth };
  },

  data() {
    return {
      MUTATION_CREATE_NEXUS_USER_EUTU,
      showPopup: false,
      dateTitles: {
        english: "",
        french: "",
      },
      isIframe: false,
      eutuInfo: {
        user_id: "",
        version: "",
        browser: "",
        ip_address: "",
        device: "",
        user_name: "",
        user_email: "",
      },
      mobileMenuOpen: false,
    };
  },
  methods: {
    toggleMobileMenu() {
      this.mobileMenuOpen = !this.mobileMenuOpen;
    },

    showEutu() {
      const { user_eutus } = this.$store.getters["user"];
      const userEutuVersion =
        Array.isArray(user_eutus) &&
        user_eutus.length &&
        user_eutus[0].hasOwnProperty("version")
          ? user_eutus[0].version
          : 0;

      const latestEutuVersion = this.$store.getters["eutu_version"];

      if (latestEutuVersion > userEutuVersion || !userEutuVersion) {
        this.createEutuInfo(latestEutuVersion);
        this.showPopup = true;
      }
    },

    async createUserEutu() {
      const input = {
        ...this.eutuInfo,
        user_id: encryptId(this.eutuInfo.user_id),
      };
      const variables = { input };

      await this.$apollo
        .mutate({
          mutation: MUTATION_CREATE_NEXUS_USER_EUTU,
          variables,
        })
        .catch((err) => {
          console.log(err);
        });

      this.showPopup = false;
    },

    async createEutuInfo(version) {
      const { slug, id, first_name, last_name, email } =
        this.$store.getters["user"];
      const token = this.$store.getters["token"];
      const userName = `${first_name} ${last_name}`;

      const identity_response = await fetch(
        `${process.env.VUE_APP_BACKEND}/api/userIp?id=${slug}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "Strict-Transport-Security": "max-age=31536000; includeSubDomains",
            "X-Frame-Options": "deny",
            "X-Content-Type-Options": "nosniff",
            "Referrer-Policy": "same-origin",
            "Content-Security-Policy":
              "default-src 'self'; script-src 'self' 'unsafe-inline';",
          },
        },
      );

      const result = await identity_response.json();
      const { last_ip } = result;
      this.eutuInfo.ip_address = last_ip;
      const userAgent = window.navigator.userAgent;
      const browser = getBrowserName(userAgent);
      const device = getUserDevice(userAgent);
      this.eutuInfo.device = device;
      this.eutuInfo.browser = browser;
      //if for some reason we didn't get a version, default to 1
      this.eutuInfo.version = version ? version : 1;
      this.eutuInfo.user_id = id;
      this.eutuInfo.user_email = email;
      this.eutuInfo.user_name = userName;
    },
  },

  components: { SideBar, Header, Loader, Icons, TopLine, TermOfUsePopup },

  computed: {
    app_loaded() {
      return this.$store.getters["loaded"];
    },

    marginLeft() {
      if (this.isIframe) return 0;

      if (this.window.width <= 768) {
        return 0;
      } else if (this.window.width <= 1024) {
        return "80px";
      } else {
        return this.sidebarWidth;
      }
    },

    ariaClassTag() {
      return this.$route.name === "ARIA" && this.isMobile
        ? "aria-mobile-wrapper"
        : "";
    },
  },

  watch: {
    //we want to close mobile menu when user goes to a link
    $route(val) {
      if (val && this.mobileMenuOpen) this.toggleMobileMenu();
    },
  },

  async created() {
    //if being rendered in an iframe, make ui adjustments and push to ARIA
    if (window.location !== window.parent.location) {
      this.isIframe = true;
      this.$router.push({ name: "ARIA" });
    }

    this.$store.commit("SET_ABB_USER", process.env.VUE_APP_USER === "abb");
    if (process.env.VUE_APP_USER === "abb") {
      const favicon = document.getElementById("favicon");
      favicon.href = process.env.BASE_URL + "abb_favicon.ico";
    }

    //dynamically set the title for Brainbox/ABB
    window.document.title =
      process.env.VUE_APP_USER === "abb"
        ? "Dashboard | ABB Inc"
        : "Dashboard | BrainBox AI";

    //get user token
    await this.$store.dispatch("get_token", {
      auth0: this.$auth0,
    });
    // get user
    await this.$store.dispatch("get_user_data", { auth0: this.$auth0 });
    //get most recent eutu version
    await this.$store.dispatch("get_eutu");

    //get decarb buildings
    //await this.$store.dispatch('get_decarb_buildings');

    // get building types / progress
    await this.$store.dispatch("get_building_details");

    //get notifications
    await this.$store.dispatch("get_notification_count");

    //wait till buildings are retrieved
    await this.$store.dispatch("get_buildings")

    // set pages
    this.$store.dispatch("set_pages");

    if (this.$store.state.eutu) {
      const { date_english, date_french } = this.$store.state.eutu;

      this.dateTitles.english = date_english;
      this.dateTitles.french = date_french;
    }

    //determine whether to show popup
    this.showEutu();

    this.$store.commit("SET_LOADED", true);
  },
};
</script>

<style lang="stylus">


#app
  -webkit-font-smoothing antialiased
  -moz-osx-font-smoothing grayscale

.fade-loader-enter-active,
.fade-loader-leave-active
  transition opacity 1s .5s
.fade-loader-enter,
.fade-loader-leave-to
  opacity 0

.main-content
  transition 0.3s ease
</style>
