import { keys } from "../config";
import moment from "moment";

export const debounce = (callback, wait, immediate = false) => {
  let timeout = null;

  return function () {
    const callNow = immediate && !timeout;
    const next = () => callback.apply(this, arguments);

    clearTimeout(timeout);
    timeout = setTimeout(next, wait);

    if (callNow) {
      next();
    }
  };
};

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const calculate_savings = (current, history, type) => {
  const items = prepare_items(current, history, true);
  const allExpected = items.reduce((acc, item) => {
    const saving = item[keys[type].saving_unit];
    const updatedExpected = !saving
      ? item[keys[type].actual]
      : item[keys[type].expected];

    return acc + updatedExpected;
  }, 0);
  const allActual = items.reduce(
    (acc, item) => acc + item[keys[type].actual],
    0,
  );
  const savings = items.reduce(
    (acc, item) => acc + item[keys[type].saving_unit],
    0,
  );
  const average = ((allExpected - allActual) / allExpected) * 100;

  return {
    ...keys[type],
    val: savings,
    percent: Math.round(average),
  };
};

export const calculate_comfort = (current, history, type) => {
  const items = prepare_items(current, history, true);
  let percentages = items.map((item) => item.comfort);

  return {
    ...keys[type],
    val:
      percentages.length === 0
        ? 0
        : percentages.reduce((acc, percent) => acc + percent, 0) /
          percentages.length,
  };
};

//this format float is to render accurate iterations for the savings forecast - using a larger float for the emissions
export const format_float = (num, isEmission) => {
  let fixed = 2;
  if (isEmission) fixed = 6;

  return parseFloat(num.toFixed(6));
};

//this function fills is any missing dates in the data so that we can have continuity in the chart
export const recursiveDateAdd = (data, dataGap, locale, hvac_type) => {
  if (!dataGap) return data.filter((item) => item.mv_date);

  let theIndex = -1;
  let theData = {};

  data.sort((a, b) => {
    return a.mv_date < b.mv_date ? -1 : a.mv_date > b.mv_date ? 1 : 0;
  });

  for (let i = 0; i < data.length; i++) {
    let current = moment.utc(data[i].mv_date).format("YYYY-MM-DD");
    let actualNextDay = moment(current).add(1, "days").format("YYYY-MM-DD");
    let nextData = i === data.length ? null : data[i + 1];
    let nextDay = nextData
      ? moment.utc(nextData.mv_date).format("YYYY-MM-DD")
      : null;

    if (nextDay && nextDay !== actualNextDay) {
      theIndex = i + 1;
      theData.mv_date = actualNextDay;
      theData.actual = data[i].actual;
      theData.expected = data[i].expected;
      theData.saving_value = data[i].saving_value;
      theData.exclude = 1;
      theData.message = "4";
      theData.messages = [
        {
          hvac_type,
          message:
            locale === "en"
              ? "BrainBox AI wasn't controlling the building during this period."
              : "BrainBox AI ne controllait pas l'édifice durant cette période.",
        },
      ];
      break;
    } else {
      theData.mv_date = false;
    }
  }

  data.splice(theIndex, 0, theData);
  return recursiveDateAdd(data, theIndex !== -1, locale, hvac_type);
};

export const filter_excluded_items = (items) => {
  return items.filter((item) => !item.exclude);
};

export const modifyExcludedValues = (data) => {
  data.forEach((item, i, arr) => {
    if (item.exclude) {
      if (arr[i - 1]) {
        item.expected = arr[i - 1].expected;
        item.actual = arr[i - 1].actual;
        item.saving_value = arr[i - 1].saving_value;
      } else {
        item.expected = 0;
        item.actual = 0;
        item.saving_value = 0;
      }
    }
  });
};

export const total_savings_per_day = (data) => {
  let newArr = [];

  data.forEach((item) => {
    if (newArr.find((el) => el.mv_date === item.mv_date)) {
      let theIndex = newArr.findIndex((el) => el.mv_date === item.mv_date);
      let exclude = newArr[theIndex].exclude;

      if (item.messageObj && item.messageObj.message) {
        if (newArr[theIndex]) newArr[theIndex].messages.push(item.messageObj);
      }

      if (!item.exclude) {
        exclude = 0;
        newArr[theIndex].exclude = exclude;
      }

      newArr[theIndex].expected += item.exclude ? 0 : item.expected;
      newArr[theIndex].actual += item.exclude ? 0 : item.actual;
      newArr[theIndex].saving_value += item.exclude ? 0 : item.saving_value;
    } else {
      if (item.exclude) {
        item.saving_value = 0;
        item.actual = 0;
        item.expected = 0;
      }

      if (item.messageObj && item.messageObj.message) {
        item.messages = [item.messageObj];
      } else {
        item.messages = [];
      }

      newArr.push(item);
    }
  });

  return newArr;
};

export const calculate_savings_percentage = (data) => {
  const expected = data.reduce((a, b) => a + b.expected, 0);
  const savings = data.reduce((a, b) => a + b.saving_value, 0);

  let savings_percent = parseFloat(((savings / expected) * 100).toFixed(1));

  if (isNaN(savings_percent)) {
    savings_percent = 0;
  }
  return savings_percent;
};

export const calculate_virtual_metering_savings = (usage, type) => {
  let totalSavings = 0;
  usage.forEach(function (item, i) {
    if (!item.exclude) totalSavings += item.saving_value;
  });

  const percentSavings = calculate_savings_percentage(usage);

  return {
    ...keys[type],
    unit: usage[0].saving_unit,
    val: totalSavings,
    percent: percentSavings,
  };
};

export const prepare_items = (current, history, exclude = false) => {
  const all_items = [...history];

  if (current) {
    all_items.unshift(current);
  }

  return exclude ? all_items.filter((item) => !item.exclude) : all_items;
};

export const set_max = (items, type) => {
  const allExpected = items.map((item) => item[keys[type].expected]);
  const allActual = items.map((item) => item[keys[type].actual]);
  const allItems = [...allExpected, ...allActual];
  return Math.max(...allItems);
};

export const set_min = (items, type) => {
  const allExpected = items.map((item) => item[keys[type].expected]);
  const allActual = items.map((item) => item[keys[type].actual]);
  const allItems = [...allExpected, ...allActual];
  return Math.min(...allItems);
};

export const currencyFormat = (num) => {
  return "$" + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
};

export const isFloat = (num) => {
  if (typeof num == "number" && !isNaN(num) && !Number.isInteger(num)) {
    return true;
  }
};

//this one is used to display the savings on the Virtual Metering Line Chart, in the text box that appears as a user moves their cursor
export const formatFloat = (num) => {
  return parseFloat(num).toLocaleString();
};

export const getBrowserName = (userAgent) => {
  // The order matters here, and this may report false positives for unlisted browsers.

  if (userAgent.includes("Firefox")) {
    // "Mozilla/5.0 (X11; Linux i686; rv:104.0) Gecko/20100101 Firefox/104.0"
    return "Mozilla Firefox";
  } else if (userAgent.includes("SamsungBrowser")) {
    // "Mozilla/5.0 (Linux; Android 9; SAMSUNG SM-G955F Build/PPR1.180610.011) AppleWebKit/537.36 (KHTML, like Gecko) SamsungBrowser/9.4 Chrome/67.0.3396.87 Mobile Safari/537.36"
    return "Samsung Internet";
  } else if (userAgent.includes("Opera") || userAgent.includes("OPR")) {
    // "Mozilla/5.0 (Macintosh; Intel Mac OS X 12_5_1) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/104.0.0.0 Safari/537.36 OPR/90.0.4480.54"
    return "Opera";
  } else if (userAgent.includes("Edge")) {
    // "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/58.0.3029.110 Safari/537.36 Edge/16.16299"
    return "Microsoft Edge (Legacy)";
  } else if (userAgent.includes("Edg")) {
    // "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/104.0.0.0 Safari/537.36 Edg/104.0.1293.70"
    return "Microsoft Edge (Chromium)";
  } else if (userAgent.includes("Chrome")) {
    // "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/104.0.0.0 Safari/537.36"
    return "Google Chrome or Chromium";
  } else if (userAgent.includes("Safari")) {
    // "Mozilla/5.0 (iPhone; CPU iPhone OS 15_6_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/15.6 Mobile/15E148 Safari/604.1"
    return "Apple Safari";
  } else {
    return "unknown";
  }
};

export const getUserDevice = (userAgent) => {
  // The order matters here, and this may report false positives for unlisted browsers.

  if (userAgent.includes("Mac")) {
    return "Macintosh";
  } else if (userAgent.includes("Windows Phone")) {
    return "Windows Phone";
  } else if (userAgent.includes("Win")) {
    return "Windows";
  } else if (userAgent.includes("iPad")) {
    return "iPad";
  } else if (userAgent.includes("Android")) {
    return "Android";
  } else if (userAgent.includes("Playbook")) {
    return "Playbook";
  } else if (userAgent.includes("Blackberry")) {
    return "Blackberry";
  } else if (userAgent.includes("iPhone")) {
    return "iPhone";
  } else {
    return "unknown";
  }
};

export const prepare_select_options = (
  options,
  val,
  labels,
  delimiter = " - ",
) => {
  return options.map((option) => {
    return {
      value: option[val],
      label:
        labels.length === 1
          ? option[labels[0]]
          : labels.map((label) => option[label]).join(delimiter),
    };
  });
};
